<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <malhas-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getAll"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MalhasService from '@/services/MalhasService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import MalhasDataTable from '@/components/perdas-tecnicas/malhas/MalhasDataTable';

export default {
  mixins: [refreshDataMixins],
  components: {
    MalhasDataTable
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getAll);
  },
  methods: {
    getAll() {
      this.loading = true;
      MalhasService.getAll()
        .then(({ data }) => (this.items = data.data))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
